export default function (type, props, affiliationContent, combinedTags, collectionContent) {
    let GTMCenter = 'CEIP';
    if (props.syndications.centers.china.syndicated === true && props.syndications.centers.china.isPrimary === true) {
        GTMCenter = 'Carnegie China';
    } else if (
        props.syndications.centers.russiaEurasia.syndicated === true &&
        props.syndications.centers.russiaEurasia.isPrimary === true
    ) {
        GTMCenter = 'Carnegie Russia Eurasia';
    } else if (
        props.syndications.centers.middleEast.syndicated === true &&
        props.syndications.centers.middleEast.isPrimary === true
    ) {
        GTMCenter = 'Carnegie Middle East Center';
    } else if (
        props.syndications.centers.europe.syndicated === true &&
        props.syndications.centers.europe.isPrimary === true
    ) {
        GTMCenter = 'Carnegie Europe';
    } else if (
        props.syndications.centers.india.syndicated === true &&
        props.syndications.centers.india.isPrimary === true
    ) {
        GTMCenter = 'Carnegie India';
    }
    if (type === 'post') {
        if (props.syndications.blogs.length) {
            const GTMType = props.syndications.blogs[0].reference.title;
            if (GTMType === 'Strategic Europe') {
                GTMCenter = 'Carnegie Europe';
            } else if (GTMType === 'Carnegie Politika') {
                GTMCenter = 'Carnegie Russia Eurasia';
            } else if (GTMType === 'Карнеги Politika') {
                GTMCenter = 'Carnegie Russia Eurasia';
            } else if (GTMType === 'Diwan') {
                GTMCenter = 'Carnegie Middle East Center';
            } else if (GTMType === 'ديوان') {
                GTMCenter = 'Carnegie Middle East Center';
            }
        }
    }
    let GTMAuthors = '';
    if (props.contributors.length) {
        props.contributors.forEach((element) => {
            GTMAuthors += element.title;
            GTMAuthors += ', ';
        });
    }
    let GTMPrograms = '';
    let GTMProjects = '';
    if (collectionContent?.length) {
        collectionContent.forEach((element) => {
            GTMProjects += element.title;
            GTMProjects += ', ';
        });
    }
    if (affiliationContent?.length) {
        affiliationContent.forEach((element) => {
            if (element.__typename === 'Syndications_Programs') {
                GTMPrograms += element.reference.title;
                GTMPrograms += ' Program, ';
            }
        });
        affiliationContent.forEach((element) => {
            if (element.__typename === 'Syndications_Projects') {
                GTMProjects += element.reference.title;
                GTMProjects += ', ';
            }
        });
    }
    let GTMRegions = '';
    let GTMTopics = '';
    if (combinedTags?.length) {
        combinedTags.forEach((element) => {
            if (element.__typename === 'Region') {
                GTMRegions += element.title;
                GTMRegions += ', ';
            } else if (element.__typename === 'Topic') {
                GTMTopics += element.title;
                GTMTopics += ', ';
            }
        });
    }
    return { GTMCenter, GTMAuthors, GTMPrograms, GTMProjects, GTMRegions, GTMTopics };
}
